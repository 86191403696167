.title {
    font-size: 280%;
}

.card_style {
    margin-left: 5em;
}

.copy_right {
    margin-top: 5%;
}

.meet_con {
    margin-top: 1%;
} 

.form_questions {
    width: 30em;
}

.img1 {
    width: 60vw;
    height: 18vw;
}

.full-screen6{
    width: 100vw;
    height: 100vh;
    display: flex;
}

.prog_main0{
     gap: 5vw; 
    margin-top: -10%;
    flex-direction: row;
}

.img_div {
    margin-top: -30%;
}

.prog_img {
    height: 20vw;
    width: 30vw;
}

.prog_div {
    width: 20%;
}

.prog_div4 {
    width: 40%;
}

.prog_div5 {
    width: 35%;
}
.prog_div3 {
    width: 15%;
}

.prog_div1 {
    width: 35%;
}

.prog_div2 {
    width: 25%;
}

.prog_main_div {
    flex-direction: row;
}




.link {
    align-self: self-start;
}

.text {
    font-size: 120%;
}

.img {
    width: 20vw;
    height: 20vw;
}

.img2 {
    width: 17vw;
    height: 17vw;
}

.img3 {
    width: 15vw;
    height: 15vw;
}

.picture{
    width: 40vw;
    height: 40vw;
}

.discovery {
    width: 10em;
    height: 3em;
    top: 20%;
}

.picture2{
    width: 60vw;
    height: 40vw;
}

.enregistrement {
    font-size: 170%;
}

.card_page2 {
    width: 42%;
    height: 70%;
    /* margin-left: 5em; */
}

.card_title {
    height: 17vh;
}

.submit_button {
    width: 8em;
    height: 2.5em;
}

.card_page1 {
    width: 42%;
    height: 50%;
    /* margin-left: 5em; */
}
.co_logo {
    height: 2.5em ;
    width: 3em;
    top: 1em
}

.foot_main_div {
     justify-content: center;
    align-items: center;
}

.foot_first_div {
    margin-top: 7%;
    flex-direction: row;
    /* justify-content: center; */
}

.foot_second_div {

    margin-left: 10vw;
    /* align-items: center; */
}

.prog1 {
    margin-top: -2%;
}


.no-padding {
    padding: 0;
}

.descr {
    font-size: 120%;
    margin-top: 1.5%;
}

.logo_indication {
        width: 3em;
        height: 3em;
}

.description {
    font-size: 120%;
    margin-top: 2%;
}

.full-screen {
    width: 100vw;
    height: 110vh;
    display: flex;
}

.full-screen1 {
    width: 100vw;
    height: 120vh;
    display: flex;
}

.full-screen2 {
    width: 100vw;
    height: 120vh;
    display: flex;
}

.prog{
    font-size: 120%;
}

.full-screen5 {
    width: 100vw;
    height: 100vh;
    display: flex;
}

.full-screen6 {
    width: 100vw;
    height: 100vh;
    display: flex;
}

.div_style {
    flex-direction: row;
    align-items: center;
}

.logo_indication2 {
    width: 2.5em;
    height: 3em;
}


.foot_page {
    width: 100vw;
    height: 80vh;
    display: flex;
}

.video-background {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100.5%;
    height: 100.5%;
    object-fit: cover;
    transform: translate(-50%, -50%);
    pointer-events: none;
}

.image {
    width: 100vw;
    left: 5%;
    margin-top: -5%;
}
.prog_main_div1 {
    flex-direction: column;
    margin-left: 5%;
    gap: 2vh;
    width: 50%;
    /* height: ; */
    /* flex-wrap: wrap; */
}
.prog_main_div2 {
    flex-direction: column;
    width: 35%;
    /* margin-left: -9vw; */
    gap: 2vh;
    /* flex-wrap: wrap; */
}


  .cadre_photo {
    height: 40vh;
  }
.logo_Meet {
    width: 35vw;
    height: 35vw;
}

.form_input {
    background: transparent;
    border: none;
    border-bottom: 2px solid white;
    color: white;
    outline: none;
    border-radius: 0;
    /* padding-left: 10%; */
    margin-bottom: 0.5em;
}

.form_input::placeholder {
    color: white;
    margin-left: 1em;
    border: none;
    outline: none;
}

.form_input:focus {
    background: transparent;
  color: white;
  border-bottom: 2px solid white;
    /* padding-left: 10%; */
    box-shadow: none;
  outline: none;

}

.general_card {
        height: 30em;
        width: 48%;
}

.first_card_section {
    margin-bottom: 10em;

}

.formular_card {
    margin-top: -5%;
    height: 65%;
    width: 55%;
}

.cadre_questions {
    height: 25%;
    width: 22em;
}

.cadre_txt {
    height: 50vh;
    width: 22em;

}

.full-screen3 {
    width: 100vw;
    height: 160vh;
    display: flex;
}

.full-screen4 {
    width: 100vw;
    height: 130vh;
    display: flex;
}


  @media (max-width: 1150px ) {
    .card_page2 {
        width: 100%;
        height: 70%;
        margin-left: 0em;
        margin-top: 1em;
    }
    .card_page1 {
        width: 100%;
        height: 40%;
        margin-left: 0em;
    }
    .full-screen2 {
        width: 100vw;
        height: 220vh;
        display: flex;
        /* align-items: center;
        justify-content: center;
        position: relative; */
         /* margin: 0;
         padding: 0; */
    }

    .foot_first_div {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .img_div {
        margin-top: -45%;
    }

    .img {
        width: 30vw;
        height: 30vw;
    }

    .img2 {
        width: 27vw;
        height: 27vw;
    }



    .img3 {
        width: 25vw;
        height: 25vw;
    }

    .picture{
        width: 60vw;
        height: 60vw;
    }
    .text {
        font-size: 100%;
    }

    .prog_img {
        height: 20vw;
        width: 30vw;
    }

    .image {
        width: 150vw;
        margin-left: 10%;
        margin-top: 4%;
    }

    .link {
        align-self: center;
    }

    .foot_main_div {
        justify-content: center;
        /* align-items: center; */
    }
    .foot_second_div {
        margin-top: 10%;
        margin-left: 0%;
        align-items: center;
    }
    .foot_page {
        width: 100vw;
        height: 100vh;
        display: flex;
    }

    .full-screen3 {
        width: 100vw;
        height: 155vh;
        display: flex;
    }

    .div_style {
        flex-direction: column;
    }
    .card_style {
        margin-left: 0em;
        margin-top: 2em;
    }

    .cadre_photo {
        height: 40vh;
    }
    .card_title {
        height: 17vh;
    }
    .cadre_questions {
        height: 25%;
        width: 20em;

    }
    .logo_indication {
        width: 2.5em;
        height: 2.5em;
}

    .logo_indication2 {
        width: 2em;
        height: 2.5em;
}
    .cadre_txt {
        height: 50vh;
        width: 20em;

    }


    .descr {
        font-size: 100%;
        margin-top: 1%;

    }

    .prog{
        font-size: 100%;
    }

    .prog_div {
    width: 20%;
    }

    .prog_div1 {
        width: 40%;
    }

    .prog_div4 {
        width: 50%;
    }

    .prog_div5 {
        width: 50%;
    }
    .prog_div3 {
        width: 19%;
    }

    .prog_div1 {
        width: 35%;
    }

    .prog_div2 {
        width: 25%;
    }

    .picture1{
        width: 120vw;
        height: 60vw;
    }

    .prog_main_div2 {
        flex-direction: column;
        /* margin-left: -3vw; */
        /* gap: 2vw; */
        /* flex-wrap: wrap; */
    }
    .picture2{
        width: 120vw;
        height: 60vw;
    }

    .full-screen5 {
        width: 100vw;
        height: 100vh;
        display: flex;
    }

    .full-screen6 {
        width: 100vw;
        height: 100vh;
        display: flex;
    }

    .formular_card {
        margin-top: 0%;
        height: 65%;
        width: 75%;
    }
    .submit_button {
        width: 5em;
        height: 1.5em;
    }
    .full-screen1 {
        width: 100vw;
        height: 150vh;
        display: flex;
    }
}

@media (max-width: 768px ) {
    .main_div {
        display: flex;
        top: 13%;
        left: 0%;
        width: 100%;
        height: 87%;
        flex-direction: column;
    }

    .first_card_section {
        height: 4.2em;
    }

    .logo_Meet {
        width: 30em;
        height: 30em;
    }

    .first_card_client {
        height: 2em;
    }

    .full-screen4 {
        height: 100vh;
    }

    .card_size {
        margin-bottom: 15px;
        margin-left: 15px;
        width: 10em;
        height: 10em;
    }

    .co_logo {
        height: auto;
        width: 2em;
    }
    .general_card {
        height: 20em;
        width: 22em;
    }

    .prog_img {
        height: 30vw;
        width: 45vw;
    }

    .discovery {
        width: 8em;
        height: 2.5em;

    }

    .img_div {
        margin-top: -45%;
    }

    .meet_con {
        margin-top: 10%;
    } 
    
    .prog_main0{
        gap: 2vh;
        margin-top: -15%;
        flex-direction: column;
    }

    .img {
        width: 30vw;
        height: 30vw;
    }

    .img2 {
        width: 27vw;
        height: 27vw;
    }

    .prog1 {
        margin-top: -4%;
    }

    .copy_right {
        margin-top: 15%;
    }
    

    .img3 {
        width: 25vw;
        height: 25vw;
    }

    .picture{
        width: 60vw;
        height: 60vw;
    }
    .enregistrement {
        font-size: 130%;
    }

    

    .full-screen2 {
        width: 100vw;
        height: 270vh;
        display: flex;
    }

    .form_questions {
        width: 90vw;
    }
    

    .mmand {
        top : 0.2em
    }
    .formular_card {
        height: 55%;
        width: 95%;
    }

    .prog_div {
        width: 100%;
    }


        /* .prog_div1 {
            width: 40%;
        } */

        .prog_div4 {
            width: 100%;
        }

        .prog_div5 {
            width: 100%;
        }
        .prog_div3 {
            width: 100%;
        }

        .prog_div1 {
            width: 100%;
        }

        .prog_div2 {
            width: 100%;
        }
        .full-screen5 {
            width: 100vw;
            height: 100vh;
            display: flex;
        }
    
        .prog_main_div1 {
            flex-direction: column;
            margin-left: 0%;
            gap: 2vh;
            width: 100%;
            /* height: ; */
            /* flex-wrap: wrap; */
        }
        .prog_main_div2 {
            flex-direction: column;
            width: 100%;
            /* margin-left: -9vw; */
            gap: 2vh;
            /* flex-wrap: wrap; */
        }
        

    .full-screen3 {
        width: 100vw;
        height: 160vh;
        display: flex;
    }
    .prog{
        font-size: 80%;
    }

    .description {
        font-size: 100%;
         margin-top: 1%;
    }

    .title {
        font-size: 220%;
    }
    .full-screen1 {
        width: 100vw;
        height: 170vh;
        display: flex;
    }
    .reseau {
        justify-content: center;
    }
  }


@font-face {
    font-family: 'Myfont1';
    src: url('./movies/LeagueSpartan-Bold.ttf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Myfont2';
    src: url('./movies/GlacialIndifference-Regular.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}
